/* eslint-disable no-underscore-dangle */

export default {
 on(events, handler, priority) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (typeof handler !== 'function') return self;
  const method = priority ? 'unshift' : 'push';
  events.split(' ').forEach((event) => {
   if (!self.eventsListeners[event]) self.eventsListeners[event] = [];
   self.eventsListeners[event][method](handler);
  });
  return self;
 },

 once(events, handler, priority) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (typeof handler !== 'function') return self;
  function onceHandler(...args) {
   self.off(events, onceHandler);
   if (onceHandler.__emitterProxy) {
    delete onceHandler.__emitterProxy;
   }
   handler.apply(self, args);
  }
  onceHandler.__emitterProxy = handler;
  return self.on(events, onceHandler, priority);
 },

 onAny(handler, priority) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (typeof handler !== 'function') return self;
  const method = priority ? 'unshift' : 'push';
  if (self.eventsAnyListeners.indexOf(handler) < 0) {
   self.eventsAnyListeners[method](handler);
  }
  return self;
 },

 offAny(handler) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (!self.eventsAnyListeners) return self;
  const index = self.eventsAnyListeners.indexOf(handler);
  if (index >= 0) {
   self.eventsAnyListeners.splice(index, 1);
  }
  return self;
 },

 off(events, handler) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (!self.eventsListeners) return self;
  events.split(' ').forEach((event) => {
   if (typeof handler === 'undefined') {
    self.eventsListeners[event] = [];
   } else if (self.eventsListeners[event]) {
    self.eventsListeners[event].forEach((eventHandler, index) => {
     if (
      eventHandler === handler ||
      (eventHandler.__emitterProxy && eventHandler.__emitterProxy === handler)
     ) {
      self.eventsListeners[event].splice(index, 1);
     }
    });
   }
  });
  return self;
 },

 emit(...args) {
  const self = this;
  if (!self.eventsListeners || self.destroyed) return self;
  if (!self.eventsListeners) return self;
  let events;
  let data;
  let context;
  if (typeof args[0] === 'string' || Array.isArray(args[0])) {
   events = args[0];
   data = args.slice(1, args.length);
   context = self;
  } else {
   events = args[0].events;
   data = args[0].data;
   context = args[0].context || self;
  }
  data.unshift(context);
  const eventsArray = Array.isArray(events) ? events : events.split(' ');

  eventsArray.forEach((event) => {
   if (self.eventsAnyListeners && self.eventsAnyListeners.length) {
    self.eventsAnyListeners.forEach((eventHandler) => {
     eventHandler.apply(context, [event, ...data]);
    });
   }
   if (self.eventsListeners && self.eventsListeners[event]) {
    self.eventsListeners[event].forEach((eventHandler) => {
     eventHandler.apply(context, data);
    });
   }
  });
  return self;
 },
};
