import { getWindow } from 'ssr-window';

export default function getBreakpoint(breakpoints, base = 'window', containerEl) {
 if (!breakpoints || (base === 'container' && !containerEl)) return undefined;
 let breakpoint = false;

 const window = getWindow();
 const currentHeight = base === 'window' ? window.innerHeight : containerEl.clientHeight;

 const points = Object.keys(breakpoints).map((point) => {
  if (typeof point === 'string' && point.indexOf('@') === 0) {
   const minRatio = parseFloat(point.substr(1));
   const value = currentHeight * minRatio;
   return { value, point };
  }
  return { value: point, point };
 });

 points.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
 for (let i = 0; i < points.length; i += 1) {
  const { point, value } = points[i];
  if (base === 'window') {
   if (window.matchMedia(`(min-width: ${value}px)`).matches) {
    breakpoint = point;
   }
  } else if (value <= containerEl.clientWidth) {
   breakpoint = point;
  }
 }
 return breakpoint || 'max';
}
