import slideTo from './slideTo.js';
import slideToLoop from './slideToLoop.js';
import slideNext from './slideNext.js';
import slidePrev from './slidePrev.js';
import slideReset from './slideReset.js';
import slideToClosest from './slideToClosest.js';
import slideToClickedSlide from './slideToClickedSlide.js';

export default {
 slideTo,
 slideToLoop,
 slideNext,
 slidePrev,
 slideReset,
 slideToClosest,
 slideToClickedSlide,
};
