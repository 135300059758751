import getTranslate from './getTranslate.js';
import setTranslate from './setTranslate.js';
import minTranslate from './minTranslate.js';
import maxTranslate from './maxTranslate.js';
import translateTo from './translateTo.js';

export default {
 getTranslate,
 setTranslate,
 minTranslate,
 maxTranslate,
 translateTo,
};
