import { animateCSSModeScroll } from '../../shared/utils.js';

export default function translateTo(
 translate = 0,
 speed = this.params.speed,
 runCallbacks = true,
 translateBounds = true,
 internal,
) {
 const swiper = this;

 const { params, wrapperEl } = swiper;

 if (swiper.animating && params.preventInteractionOnTransition) {
  return false;
 }

 const minTranslate = swiper.minTranslate();
 const maxTranslate = swiper.maxTranslate();
 let newTranslate;
 if (translateBounds && translate > minTranslate) newTranslate = minTranslate;
 else if (translateBounds && translate < maxTranslate) newTranslate = maxTranslate;
 else newTranslate = translate;

 // Update progress
 swiper.updateProgress(newTranslate);

 if (params.cssMode) {
  const isH = swiper.isHorizontal();
  if (speed === 0) {
   wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = -newTranslate;
  } else {
   if (!swiper.support.smoothScroll) {
    animateCSSModeScroll({ swiper, targetPosition: -newTranslate, side: isH ? 'left' : 'top' });
    return true;
   }
   wrapperEl.scrollTo({
    [isH ? 'left' : 'top']: -newTranslate,
    behavior: 'smooth',
   });
  }
  return true;
 }

 if (speed === 0) {
  swiper.setTransition(0);
  swiper.setTranslate(newTranslate);
  if (runCallbacks) {
   swiper.emit('beforeTransitionStart', speed, internal);
   swiper.emit('transitionEnd');
  }
 } else {
  swiper.setTransition(speed);
  swiper.setTranslate(newTranslate);
  if (runCallbacks) {
   swiper.emit('beforeTransitionStart', speed, internal);
   swiper.emit('transitionStart');
  }
  if (!swiper.animating) {
   swiper.animating = true;
   if (!swiper.onTranslateToWrapperTransitionEnd) {
    swiper.onTranslateToWrapperTransitionEnd = function transitionEnd(e) {
     if (!swiper || swiper.destroyed) return;
     if (e.target !== this) return;
     swiper.$wrapperEl[0].removeEventListener(
      'transitionend',
      swiper.onTranslateToWrapperTransitionEnd,
     );
     swiper.$wrapperEl[0].removeEventListener(
      'webkitTransitionEnd',
      swiper.onTranslateToWrapperTransitionEnd,
     );
     swiper.onTranslateToWrapperTransitionEnd = null;
     delete swiper.onTranslateToWrapperTransitionEnd;
     if (runCallbacks) {
      swiper.emit('transitionEnd');
     }
    };
   }
   swiper.$wrapperEl[0].addEventListener(
    'transitionend',
    swiper.onTranslateToWrapperTransitionEnd,
   );
   swiper.$wrapperEl[0].addEventListener(
    'webkitTransitionEnd',
    swiper.onTranslateToWrapperTransitionEnd,
   );
  }
 }

 return true;
}
