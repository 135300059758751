import $ from './dom.js';

export default function createShadow(params, $slideEl, side) {
 const shadowClass = `swiper-slide-shadow${side ? `-${side}` : ''}`;
 const $shadowContainer = params.transformEl ? $slideEl.find(params.transformEl) : $slideEl;
 let $shadowEl = $shadowContainer.children(`.${shadowClass}`);

 if (!$shadowEl.length) {
  $shadowEl = $(`<div class="swiper-slide-shadow${side ? `-${side}` : ''}"></div>`);
  $shadowContainer.append($shadowEl);
 }
 return $shadowEl;
}
