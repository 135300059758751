import $ from '../../shared/dom.js';

export default function updateClickedSlide(e) {
 const swiper = this;
 const params = swiper.params;
 const slide = $(e).closest(`.${params.slideClass}`)[0];
 let slideFound = false;
 let slideIndex;

 if (slide) {
  for (let i = 0; i < swiper.slides.length; i += 1) {
   if (swiper.slides[i] === slide) {
    slideFound = true;
    slideIndex = i;
    break;
   }
  }
 }

 if (slide && slideFound) {
  swiper.clickedSlide = slide;
  if (swiper.virtual && swiper.params.virtual.enabled) {
   swiper.clickedIndex = parseInt($(slide).attr('data-swiper-slide-index'), 10);
  } else {
   swiper.clickedIndex = slideIndex;
  }
 } else {
  swiper.clickedSlide = undefined;
  swiper.clickedIndex = undefined;
  return;
 }
 if (
  params.slideToClickedSlide &&
  swiper.clickedIndex !== undefined &&
  swiper.clickedIndex !== swiper.activeIndex
 ) {
  swiper.slideToClickedSlide();
 }
}
