import { getWindow } from 'ssr-window';
import $ from '../../shared/dom.js';

export default function loadImage(imageEl, src, srcset, sizes, checkForComplete, callback) {
 const window = getWindow();
 let image;
 function onReady() {
  if (callback) callback();
 }
 const isPicture = $(imageEl).parent('picture')[0];

 if (!isPicture && (!imageEl.complete || !checkForComplete)) {
  if (src) {
   image = new window.Image();
   image.onload = onReady;
   image.onerror = onReady;
   if (sizes) {
    image.sizes = sizes;
   }
   if (srcset) {
    image.srcset = srcset;
   }
   if (src) {
    image.src = src;
   }
  } else {
   onReady();
  }
 } else {
  // image already loaded...
  onReady();
 }
}
