import { getDocument } from 'ssr-window';
import $ from '../../shared/dom.js';
import { now } from '../../shared/utils.js';

export default function onTouchMove(event) {
 const document = getDocument();
 const swiper = this;
 const data = swiper.touchEventsData;
 const { params, touches, rtlTranslate: rtl, enabled } = swiper;
 if (!enabled) return;
 let e = event;
 if (e.originalEvent) e = e.originalEvent;
 if (!data.isTouched) {
  if (data.startMoving && data.isScrolling) {
   swiper.emit('touchMoveOpposite', e);
  }
  return;
 }
 if (data.isTouchEvent && e.type !== 'touchmove') return;
 const targetTouch =
  e.type === 'touchmove' && e.targetTouches && (e.targetTouches[0] || e.changedTouches[0]);
 const pageX = e.type === 'touchmove' ? targetTouch.pageX : e.pageX;
 const pageY = e.type === 'touchmove' ? targetTouch.pageY : e.pageY;
 if (e.preventedByNestedSwiper) {
  touches.startX = pageX;
  touches.startY = pageY;
  return;
 }
 if (!swiper.allowTouchMove) {
  if (!$(e.target).is(data.focusableElements)) {
   swiper.allowClick = false;
  }
  if (data.isTouched) {
   Object.assign(touches, {
    startX: pageX,
    startY: pageY,
    currentX: pageX,
    currentY: pageY,
   });
   data.touchStartTime = now();
  }
  return;
 }
 if (data.isTouchEvent && params.touchReleaseOnEdges && !params.loop) {
  if (swiper.isVertical()) {
   // Vertical
   if (
    (pageY < touches.startY && swiper.translate <= swiper.maxTranslate()) ||
    (pageY > touches.startY && swiper.translate >= swiper.minTranslate())
   ) {
    data.isTouched = false;
    data.isMoved = false;
    return;
   }
  } else if (
   (pageX < touches.startX && swiper.translate <= swiper.maxTranslate()) ||
   (pageX > touches.startX && swiper.translate >= swiper.minTranslate())
  ) {
   return;
  }
 }
 if (data.isTouchEvent && document.activeElement) {
  if (e.target === document.activeElement && $(e.target).is(data.focusableElements)) {
   data.isMoved = true;
   swiper.allowClick = false;
   return;
  }
 }
 if (data.allowTouchCallbacks) {
  swiper.emit('touchMove', e);
 }
 if (e.targetTouches && e.targetTouches.length > 1) return;

 touches.currentX = pageX;
 touches.currentY = pageY;

 const diffX = touches.currentX - touches.startX;
 const diffY = touches.currentY - touches.startY;
 if (swiper.params.threshold && Math.sqrt(diffX ** 2 + diffY ** 2) < swiper.params.threshold)
  return;

 if (typeof data.isScrolling === 'undefined') {
  let touchAngle;
  if (
   (swiper.isHorizontal() && touches.currentY === touches.startY) ||
   (swiper.isVertical() && touches.currentX === touches.startX)
  ) {
   data.isScrolling = false;
  } else {
   // eslint-disable-next-line
   if (diffX * diffX + diffY * diffY >= 25) {
    touchAngle = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;
    data.isScrolling = swiper.isHorizontal()
     ? touchAngle > params.touchAngle
     : 90 - touchAngle > params.touchAngle;
   }
  }
 }
 if (data.isScrolling) {
  swiper.emit('touchMoveOpposite', e);
 }
 if (typeof data.startMoving === 'undefined') {
  if (touches.currentX !== touches.startX || touches.currentY !== touches.startY) {
   data.startMoving = true;
  }
 }
 if (data.isScrolling) {
  data.isTouched = false;
  return;
 }
 if (!data.startMoving) {
  return;
 }
 swiper.allowClick = false;
 if (!params.cssMode && e.cancelable) {
  e.preventDefault();
 }
 if (params.touchMoveStopPropagation && !params.nested) {
  e.stopPropagation();
 }

 if (!data.isMoved) {
  if (params.loop && !params.cssMode) {
   swiper.loopFix();
  }
  data.startTranslate = swiper.getTranslate();
  swiper.setTransition(0);
  if (swiper.animating) {
   swiper.$wrapperEl.trigger('webkitTransitionEnd transitionend');
  }
  data.allowMomentumBounce = false;
  // Grab Cursor
  if (params.grabCursor && (swiper.allowSlideNext === true || swiper.allowSlidePrev === true)) {
   swiper.setGrabCursor(true);
  }
  swiper.emit('sliderFirstMove', e);
 }
 swiper.emit('sliderMove', e);
 data.isMoved = true;

 let diff = swiper.isHorizontal() ? diffX : diffY;
 touches.diff = diff;

 diff *= params.touchRatio;
 if (rtl) diff = -diff;

 swiper.swipeDirection = diff > 0 ? 'prev' : 'next';
 data.currentTranslate = diff + data.startTranslate;

 let disableParentSwiper = true;
 let resistanceRatio = params.resistanceRatio;
 if (params.touchReleaseOnEdges) {
  resistanceRatio = 0;
 }
 if (diff > 0 && data.currentTranslate > swiper.minTranslate()) {
  disableParentSwiper = false;
  if (params.resistance)
   data.currentTranslate =
    swiper.minTranslate() -
    1 +
    (-swiper.minTranslate() + data.startTranslate + diff) ** resistanceRatio;
 } else if (diff < 0 && data.currentTranslate < swiper.maxTranslate()) {
  disableParentSwiper = false;
  if (params.resistance)
   data.currentTranslate =
    swiper.maxTranslate() +
    1 -
    (swiper.maxTranslate() - data.startTranslate - diff) ** resistanceRatio;
 }

 if (disableParentSwiper) {
  e.preventedByNestedSwiper = true;
 }

 // Directions locks
 if (
  !swiper.allowSlideNext &&
  swiper.swipeDirection === 'next' &&
  data.currentTranslate < data.startTranslate
 ) {
  data.currentTranslate = data.startTranslate;
 }
 if (
  !swiper.allowSlidePrev &&
  swiper.swipeDirection === 'prev' &&
  data.currentTranslate > data.startTranslate
 ) {
  data.currentTranslate = data.startTranslate;
 }
 if (!swiper.allowSlidePrev && !swiper.allowSlideNext) {
  data.currentTranslate = data.startTranslate;
 }

 // Threshold
 if (params.threshold > 0) {
  if (Math.abs(diff) > params.threshold || data.allowThresholdMove) {
   if (!data.allowThresholdMove) {
    data.allowThresholdMove = true;
    touches.startX = touches.currentX;
    touches.startY = touches.currentY;
    data.currentTranslate = data.startTranslate;
    touches.diff = swiper.isHorizontal()
     ? touches.currentX - touches.startX
     : touches.currentY - touches.startY;
    return;
   }
  } else {
   data.currentTranslate = data.startTranslate;
   return;
  }
 }

 if (!params.followFinger || params.cssMode) return;

 // Update active index in free mode
 if (
  (params.freeMode && params.freeMode.enabled && swiper.freeMode) ||
  params.watchSlidesProgress
 ) {
  swiper.updateActiveIndex();
  swiper.updateSlidesClasses();
 }
 if (swiper.params.freeMode && params.freeMode.enabled && swiper.freeMode) {
  swiper.freeMode.onTouchMove();
 }
 // Update progress
 swiper.updateProgress(data.currentTranslate);
 // Update translate
 swiper.setTranslate(data.currentTranslate);
}
