import { getWindow } from 'ssr-window';

let browser;

function calcBrowser() {
 const window = getWindow();
 function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0 && ua.indexOf('android') < 0;
 }
 return {
  isSafari: isSafari(),
  isWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window.navigator.userAgent),
 };
}

function getBrowser() {
 if (!browser) {
  browser = calcBrowser();
 }
 return browser;
}

export { getBrowser };
