import { getDocument } from 'ssr-window';

import onTouchStart from './onTouchStart.js';
import onTouchMove from './onTouchMove.js';
import onTouchEnd from './onTouchEnd.js';
import onResize from './onResize.js';
import onClick from './onClick.js';
import onScroll from './onScroll.js';

let dummyEventAttached = false;
function dummyEventListener() {}

const events = (swiper, method) => {
 const document = getDocument();
 const { params, touchEvents, el, wrapperEl, device, support } = swiper;
 const capture = !!params.nested;
 const domMethod = method === 'on' ? 'addEventListener' : 'removeEventListener';
 const swiperMethod = method;

 // Touch Events
 if (!support.touch) {
  el[domMethod](touchEvents.start, swiper.onTouchStart, false);
  document[domMethod](touchEvents.move, swiper.onTouchMove, capture);
  document[domMethod](touchEvents.end, swiper.onTouchEnd, false);
 } else {
  const passiveListener =
   touchEvents.start === 'touchstart' && support.passiveListener && params.passiveListeners
    ? { passive: true, capture: false }
    : false;
  el[domMethod](touchEvents.start, swiper.onTouchStart, passiveListener);
  el[domMethod](
   touchEvents.move,
   swiper.onTouchMove,
   support.passiveListener ? { passive: false, capture } : capture,
  );
  el[domMethod](touchEvents.end, swiper.onTouchEnd, passiveListener);
  if (touchEvents.cancel) {
   el[domMethod](touchEvents.cancel, swiper.onTouchEnd, passiveListener);
  }
 }
 // Prevent Links Clicks
 if (params.preventClicks || params.preventClicksPropagation) {
  el[domMethod]('click', swiper.onClick, true);
 }
 if (params.cssMode) {
  wrapperEl[domMethod]('scroll', swiper.onScroll);
 }

 // Resize handler
 if (params.updateOnWindowResize) {
  swiper[swiperMethod](
   device.ios || device.android
    ? 'resize orientationchange observerUpdate'
    : 'resize observerUpdate',
   onResize,
   true,
  );
 } else {
  swiper[swiperMethod]('observerUpdate', onResize, true);
 }
};

function attachEvents() {
 const swiper = this;
 const document = getDocument();
 const { params, support } = swiper;

 swiper.onTouchStart = onTouchStart.bind(swiper);
 swiper.onTouchMove = onTouchMove.bind(swiper);
 swiper.onTouchEnd = onTouchEnd.bind(swiper);

 if (params.cssMode) {
  swiper.onScroll = onScroll.bind(swiper);
 }

 swiper.onClick = onClick.bind(swiper);

 if (support.touch && !dummyEventAttached) {
  document.addEventListener('touchstart', dummyEventListener);
  dummyEventAttached = true;
 }

 events(swiper, 'on');
}

function detachEvents() {
 const swiper = this;
 events(swiper, 'off');
}

export default {
 attachEvents,
 detachEvents,
};
