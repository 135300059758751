export default function loopFix() {
 const swiper = this;

 swiper.emit('beforeLoopFix');

 const {
  activeIndex,
  slides,
  loopedSlides,
  allowSlidePrev,
  allowSlideNext,
  snapGrid,
  rtlTranslate: rtl,
 } = swiper;
 let newIndex;
 swiper.allowSlidePrev = true;
 swiper.allowSlideNext = true;

 const snapTranslate = -snapGrid[activeIndex];
 const diff = snapTranslate - swiper.getTranslate();

 // Fix For Negative Oversliding
 if (activeIndex < loopedSlides) {
  newIndex = slides.length - loopedSlides * 3 + activeIndex;
  newIndex += loopedSlides;
  const slideChanged = swiper.slideTo(newIndex, 0, false, true);
  if (slideChanged && diff !== 0) {
   swiper.setTranslate((rtl ? -swiper.translate : swiper.translate) - diff);
  }
 } else if (activeIndex >= slides.length - loopedSlides) {
  // Fix For Positive Oversliding
  newIndex = -slides.length + activeIndex + loopedSlides;
  newIndex += loopedSlides;
  const slideChanged = swiper.slideTo(newIndex, 0, false, true);
  if (slideChanged && diff !== 0) {
   swiper.setTranslate((rtl ? -swiper.translate : swiper.translate) - diff);
  }
 }
 swiper.allowSlidePrev = allowSlidePrev;
 swiper.allowSlideNext = allowSlideNext;

 swiper.emit('loopFix');
}
