import $ from '../../shared/dom.js';

export default function Parallax({ swiper, extendParams, on }) {
 extendParams({
  parallax: {
   enabled: false,
  },
 });

 const setTransform = (el, progress) => {
  const { rtl } = swiper;

  const $el = $(el);
  const rtlFactor = rtl ? -1 : 1;

  const p = $el.attr('data-swiper-parallax') || '0';
  let x = $el.attr('data-swiper-parallax-x');
  let y = $el.attr('data-swiper-parallax-y');
  const scale = $el.attr('data-swiper-parallax-scale');
  const opacity = $el.attr('data-swiper-parallax-opacity');

  if (x || y) {
   x = x || '0';
   y = y || '0';
  } else if (swiper.isHorizontal()) {
   x = p;
   y = '0';
  } else {
   y = p;
   x = '0';
  }

  if (x.indexOf('%') >= 0) {
   x = `${parseInt(x, 10) * progress * rtlFactor}%`;
  } else {
   x = `${x * progress * rtlFactor}px`;
  }
  if (y.indexOf('%') >= 0) {
   y = `${parseInt(y, 10) * progress}%`;
  } else {
   y = `${y * progress}px`;
  }

  if (typeof opacity !== 'undefined' && opacity !== null) {
   const currentOpacity = opacity - (opacity - 1) * (1 - Math.abs(progress));
   $el[0].style.opacity = currentOpacity;
  }
  if (typeof scale === 'undefined' || scale === null) {
   $el.transform(`translate3d(${x}, ${y}, 0px)`);
  } else {
   const currentScale = scale - (scale - 1) * (1 - Math.abs(progress));
   $el.transform(`translate3d(${x}, ${y}, 0px) scale(${currentScale})`);
  }
 };

 const setTranslate = () => {
  const { $el, slides, progress, snapGrid } = swiper;
  $el
   .children(
    '[data-swiper-parallax], [data-swiper-parallax-x], [data-swiper-parallax-y], [data-swiper-parallax-opacity], [data-swiper-parallax-scale]',
   )
   .each((el) => {
    setTransform(el, progress);
   });
  slides.each((slideEl, slideIndex) => {
   let slideProgress = slideEl.progress;
   if (swiper.params.slidesPerGroup > 1 && swiper.params.slidesPerView !== 'auto') {
    slideProgress += Math.ceil(slideIndex / 2) - progress * (snapGrid.length - 1);
   }
   slideProgress = Math.min(Math.max(slideProgress, -1), 1);
   $(slideEl)
    .find(
     '[data-swiper-parallax], [data-swiper-parallax-x], [data-swiper-parallax-y], [data-swiper-parallax-opacity], [data-swiper-parallax-scale]',
    )
    .each((el) => {
     setTransform(el, slideProgress);
    });
  });
 };

 const setTransition = (duration = swiper.params.speed) => {
  const { $el } = swiper;
  $el
   .find(
    '[data-swiper-parallax], [data-swiper-parallax-x], [data-swiper-parallax-y], [data-swiper-parallax-opacity], [data-swiper-parallax-scale]',
   )
   .each((parallaxEl) => {
    const $parallaxEl = $(parallaxEl);
    let parallaxDuration =
     parseInt($parallaxEl.attr('data-swiper-parallax-duration'), 10) || duration;
    if (duration === 0) parallaxDuration = 0;
    $parallaxEl.transition(parallaxDuration);
   });
 };

 on('beforeInit', () => {
  if (!swiper.params.parallax.enabled) return;
  swiper.params.watchSlidesProgress = true;
  swiper.originalParams.watchSlidesProgress = true;
 });
 on('init', () => {
  if (!swiper.params.parallax.enabled) return;
  setTranslate();
 });
 on('setTranslate', () => {
  if (!swiper.params.parallax.enabled) return;
  setTranslate();
 });
 on('setTransition', (_swiper, duration) => {
  if (!swiper.params.parallax.enabled) return;
  setTransition(duration);
 });
}
