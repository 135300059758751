/* eslint no-unused-vars: "off" */
export default function slidePrev(speed = this.params.speed, runCallbacks = true, internal) {
 const swiper = this;
 const { params, animating, snapGrid, slidesGrid, rtlTranslate, enabled } = swiper;
 if (!enabled) return swiper;

 if (params.loop) {
  if (animating && params.loopPreventsSlide) return false;
  swiper.loopFix();
  // eslint-disable-next-line
  swiper._clientLeft = swiper.$wrapperEl[0].clientLeft;
 }
 const translate = rtlTranslate ? swiper.translate : -swiper.translate;

 function normalize(val) {
  if (val < 0) return -Math.floor(Math.abs(val));
  return Math.floor(val);
 }
 const normalizedTranslate = normalize(translate);
 const normalizedSnapGrid = snapGrid.map((val) => normalize(val));

 let prevSnap = snapGrid[normalizedSnapGrid.indexOf(normalizedTranslate) - 1];
 if (typeof prevSnap === 'undefined' && params.cssMode) {
  let prevSnapIndex;
  snapGrid.forEach((snap, snapIndex) => {
   if (normalizedTranslate >= snap) {
    // prevSnap = snap;
    prevSnapIndex = snapIndex;
   }
  });
  if (typeof prevSnapIndex !== 'undefined') {
   prevSnap = snapGrid[prevSnapIndex > 0 ? prevSnapIndex - 1 : prevSnapIndex];
  }
 }
 let prevIndex = 0;
 if (typeof prevSnap !== 'undefined') {
  prevIndex = slidesGrid.indexOf(prevSnap);
  if (prevIndex < 0) prevIndex = swiper.activeIndex - 1;
  if (
   params.slidesPerView === 'auto' &&
   params.slidesPerGroup === 1 &&
   params.slidesPerGroupAuto
  ) {
   prevIndex = prevIndex - swiper.slidesPerViewDynamic('previous', true) + 1;
   prevIndex = Math.max(prevIndex, 0);
  }
 }
 if (params.rewind && swiper.isBeginning) {
  const lastIndex =
   swiper.params.virtual && swiper.params.virtual.enabled && swiper.virtual
    ? swiper.virtual.slides.length - 1
    : swiper.slides.length - 1;
  return swiper.slideTo(lastIndex, speed, runCallbacks, internal);
 }
 return swiper.slideTo(prevIndex, speed, runCallbacks, internal);
}
