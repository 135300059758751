import { getWindow, getDocument } from 'ssr-window';

let support;

function calcSupport() {
 const window = getWindow();
 const document = getDocument();

 return {
  smoothScroll: document.documentElement && 'scrollBehavior' in document.documentElement.style,

  touch: !!(
   'ontouchstart' in window ||
   (window.DocumentTouch && document instanceof window.DocumentTouch)
  ),

  passiveListener: (function checkPassiveListener() {
   let supportsPassive = false;
   try {
    const opts = Object.defineProperty({}, 'passive', {
     // eslint-disable-next-line
     get() {
      supportsPassive = true;
     },
    });
    window.addEventListener('testPassiveListener', null, opts);
   } catch (e) {
    // No support
   }
   return supportsPassive;
  })(),

  gestures: (function checkGestures() {
   return 'ongesturestart' in window;
  })(),
 };
}

function getSupport() {
 if (!support) {
  support = calcSupport();
 }
 return support;
}

export { getSupport };
