export default function removeSlide(slidesIndexes) {
 const swiper = this;
 const { params, $wrapperEl, activeIndex } = swiper;

 let activeIndexBuffer = activeIndex;
 if (params.loop) {
  activeIndexBuffer -= swiper.loopedSlides;
  swiper.loopDestroy();
  swiper.slides = $wrapperEl.children(`.${params.slideClass}`);
 }
 let newActiveIndex = activeIndexBuffer;
 let indexToRemove;

 if (typeof slidesIndexes === 'object' && 'length' in slidesIndexes) {
  for (let i = 0; i < slidesIndexes.length; i += 1) {
   indexToRemove = slidesIndexes[i];
   if (swiper.slides[indexToRemove]) swiper.slides.eq(indexToRemove).remove();
   if (indexToRemove < newActiveIndex) newActiveIndex -= 1;
  }
  newActiveIndex = Math.max(newActiveIndex, 0);
 } else {
  indexToRemove = slidesIndexes;
  if (swiper.slides[indexToRemove]) swiper.slides.eq(indexToRemove).remove();
  if (indexToRemove < newActiveIndex) newActiveIndex -= 1;
  newActiveIndex = Math.max(newActiveIndex, 0);
 }

 if (params.loop) {
  swiper.loopCreate();
 }

 if (!params.observer) {
  swiper.update();
 }
 if (params.loop) {
  swiper.slideTo(newActiveIndex + swiper.loopedSlides, 0, false);
 } else {
  swiper.slideTo(newActiveIndex, 0, false);
 }
}
