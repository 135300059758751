function prepareClasses(entries, prefix) {
 const resultClasses = [];
 entries.forEach((item) => {
  if (typeof item === 'object') {
   Object.keys(item).forEach((classNames) => {
    if (item[classNames]) {
     resultClasses.push(prefix + classNames);
    }
   });
  } else if (typeof item === 'string') {
   resultClasses.push(prefix + item);
  }
 });
 return resultClasses;
}

export default function addClasses() {
 const swiper = this;
 const { classNames, params, rtl, $el, device, support } = swiper;
 // prettier-ignore
 const suffixes = prepareClasses([
  'initialized',
  params.direction,
  { 'pointer-events': !support.touch },
  { 'free-mode': swiper.params.freeMode && params.freeMode.enabled },
  { 'autoheight': params.autoHeight },
  { 'rtl': rtl },
  { 'grid': params.grid && params.grid.rows > 1 },
  { 'grid-column': params.grid && params.grid.rows > 1 && params.grid.fill === 'column' },
  { 'android': device.android },
  { 'ios': device.ios },
  { 'css-mode': params.cssMode },
  { 'centered': params.cssMode && params.centeredSlides },
  { 'watch-progress': params.watchSlidesProgress },
 ], params.containerModifierClass);
 classNames.push(...suffixes);
 $el.addClass([...classNames].join(' '));
 swiper.emitContainerClasses();
}
